import {
    EditButton,
    ShowButton,
    useCreate,
    useUpdate,
    useTranslate,
    useNotify,
    useRefresh,
    usePermissions,
} from "react-admin";
import { Box, Dialog, Typography } from "@mui/material";
import { EditView } from "./EditView";
import { ShowView } from "./ShowView";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const MediaDialog = ({ mediaTools, setMediaTools }) => {
    const [update] = useUpdate();
    const [create] = useCreate();
    const refresh = useRefresh();
    const translate = useTranslate();
    const notify = useNotify();
    const { isLoading, permissions } = usePermissions();
    let title = "";

    // eslint-disable-next-line default-case
    switch (mediaTools.type) {
        case "file":
            title = translate("equip.uploadMedia");
            break;
        case "link":
            title = translate("equip.linkToExternalResource");
            break;
        case "text":
            title = translate("equip.addText");
            break;
    }

    const createMedia = (inputData) => {
        const file = inputData.files;
        if (mediaTools.id) {
            update(
                "media",
                {
                    id: mediaTools.id,
                    data: {
                        shortname: inputData.shortname,
                        ...(inputData.url && { url: inputData.url }),
                        ...(inputData.content && {
                            content: inputData.content,
                        }),
                    },
                    previousData: mediaTools.record,
                },
                {
                    onSettled: () => {
                        setMediaTools({
                            edit: null,
                            type: null,
                            id: null,
                            record: null,
                        });
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        } else {
            create(
                "media",
                {
                    data: {
                        product: inputData.id,
                        shortname: inputData.shortname ? inputData.shortname : file.title,
                        public: true,
                        type: mediaTools.type,
                        ...(inputData.url && { url: inputData.url }),
                        ...(inputData.content && {
                            content: inputData.content,
                        }),
                        ...(file && {
                            mimetype: file.rawFile.type,
                            size: file.rawFile.size,
                            files: [file],
                        }),
                    },
                },
                {
                    onSettled: () => {
                        setMediaTools({
                            edit: null,
                            type: null,
                            id: null,
                            record: null,
                        });
                        refresh();
                    },
                    onError: (e) => {
                        handleHttpError(e, notify);
                    },
                }
            );
        }
    };

    const onClickFx = (e, edit) => {
        e.preventDefault();
        setMediaTools({
            ...mediaTools,
            edit: edit,
        });
    };

    if (isLoading) {
        return <></>;
    }
    return (
        <Dialog open={mediaTools ? true : false} fullWidth>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    pt: 2,
                    px: 4,
                }}
            >
                <Typography variant="p">{mediaTools.edit && title}</Typography>
                {mediaTools.edit &&
                mediaTools.id &&
                permissions.vote({
                    resource: "media",
                    attribute: "read",
                    subject: mediaTools.record,
                }) ? (
                    <ShowButton
                        onClick={(e) => onClickFx(e, false)}
                        title={translate("equip.show")}
                    />
                ) : (
                    mediaTools.id &&
                    permissions.vote({
                        resource: "media",
                        attribute: "edit",
                        subject: mediaTools.record,
                    }) && (
                        <EditButton
                            title={translate("equip.edit")}
                            onClick={(e) => onClickFx(e, true)}
                        />
                    )
                )}
            </Box>
            {mediaTools.edit &&
                permissions.vote({
                    resource: "media",
                    attribute: "edit",
                    subject: mediaTools.record,
                }) && (
                    <EditView
                        createMedia={createMedia}
                        mediaTools={mediaTools}
                        setMediaTools={setMediaTools}
                    />
                )}

            {!mediaTools.edit &&
                permissions.vote({
                    resource: "media",
                    attribute: "read",
                    subject: mediaTools.record,
                }) && <ShowView mediaTools={mediaTools} setMediaTools={setMediaTools} />}
        </Dialog>
    );
};
