import { FunctionField, NumberField, TextField, useTranslate, usePermissions } from "react-admin";
import { ReferenceManyFieldWithLinkSupport } from "../../../../components/ReferenceManyFieldWithLinkSupport";
import { Box, Button } from "@mui/material";
import { DatagridWithHistory } from "../../../../components/DatagridWithHistory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { RaDateField } from "../../../../components/fields/Ra/RaDateField";

export const ProductTabContentSKU = (props) => {
    const translate = useTranslate();
    const record = props.arrivalData;
    const resource = "arrivals";
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    return (
        <Box>
            {permissions.vote({
                resource,
                attribute: "create",
                subject: record,
            }) && (
                <Box sx={{ my: 2, mx: 1 }}>
                    <Button
                        onClick={() => props.setDeliveryWindow("edit")}
                        size="small"
                        variant="text"
                        startIcon={<LocalShippingIcon />}
                        title={translate("equip.newDelivery")}
                        aria-label={translate("equip.newDelivery")}
                    >
                        {translate("equip.newDelivery")}
                    </Button>
                </Box>
            )}
            <ReferenceManyFieldWithLinkSupport reference="skus" target="product" addLabel={false}>
                <DatagridWithHistory bulkActionButtons={false}>
                    <TextField label="equip.labelNumber" source="labelNumber" />
                    <RaDateField label="equip.created" source="createdAt" />
                    <TextField label="equip.manufacturer" source="manufacturer.name" />
                    <TextField label="equip.supplier" source="supplier.name" />
                    <NumberField
                        label="equip.purchasePrice"
                        source="arrival.purchasePrice"
                        options={{
                            style: "currency",
                            currency: "EUR",
                        }}
                    />
                    <NumberField
                        label="equip.currentPrice"
                        source="currentPrice"
                        options={{
                            style: "currency",
                            currency: "EUR",
                        }}
                        sortable={false}
                    />
                    <NumberField
                        label="equip.weight"
                        source="weight"
                        options={{
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            style: "unit",
                            unit: "kilogram",
                        }}
                    />
                    <FunctionField
                        label="equip.status"
                        render={(record) => translate(`equip.sku_status_${record.status}`)}
                    />
                    <TextField label="equip.serialNumber" source="serialNumber" />
                </DatagridWithHistory>
            </ReferenceManyFieldWithLinkSupport>
        </Box>
    );
};
