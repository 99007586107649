import {
  required,
  Toolbar,
  TextField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  NumberField,
  Datagrid,
  useTranslate,
  usePermissions,
} from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { RaDateField } from "../../../../components/fields/Ra/RaDateField";

export const ViewSkuArrival = (props) => {
  const translate = useTranslate();
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return <></>;
  }

  return (
    <Show
      resource="arrivals"
      id={props.arrivalData?.id}
      sx={{
        "& .RaShow-card": { background: "inherit", boxShadow: "none" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          mb: 2,
        }}
      >
        <Typography variant="p">{translate("equip.viewArrival")}</Typography>
        <Button
          onClick={() => props.setPrintLabels(true)}
          size="small"
          variant="text"
          startIcon={<PrintIcon />}
          title={translate("equip.printLabels")}
          aria-label={translate("equip.printLabels")}
        >
          {translate("equip.printLabels")}
        </Button>
      </Box>

      <SimpleShowLayout>
        <TextField
          label="equip.purchaseOrderNumber"
          source="purchaseOrderNumber"
          validate={[required()]}
          fullWidth
        />
        <NumberField
          label="equip.purchasePricePerItem"
          source="purchasePrice"
          options={{ style: "currency", currency: "EUR" }}
        />
        <RaDateField
          label="equip.orderDate"
          source="orderDate"
          validate={[required()]}
        />
        <RaDateField
          label="equip.deliveryDate"
          source="deliveryDate"
          validate={[required()]}
        />
        <ReferenceField
          label="equip.supplier"
          source="supplierId"
          reference="suppliers"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
      <Box m={2}>
        <ReferenceManyField
          reference="skus"
          addLabel={false}
          perPage={100}
          target="arrival"
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{ "& .RaDatagrid-headerCell": { backgroundColor: "inherit" } }}
          >
            <TextField label="equip.labelNumber" source="labelNumber" />
            <TextField label="equip.manufacturer" source="manufacturer.name" />
            <TextField label="equip.supplier" source="supplier.name" />
            <NumberField
              label="equip.purchasePrice"
              source="arrival.purchasePrice"
              options={{
                style: "currency",
                currency: "EUR",
              }}
            />
            <NumberField
              label="equip.currentPrice"
              source="currentPrice"
              options={{
                style: "currency",
                currency: "EUR",
              }}
              sortable={false}
            />
            <NumberField
              label="equip.weight"
              source="weight"
              options={{
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                unit: "kilogram",
              }}
            />
            <TextField
              label="equip.serialNumber"
              source="serialNumber"
              emptyText="N/A"
            />
            <TextField label="equip.comment" source="comment" />
          </Datagrid>
        </ReferenceManyField>
      </Box>
      {permissions.vote({
        resource: "skus",
        attribute: "create",
        subject: props.skuRecord,
      }) && (
        <Button
          onClick={() => {
            props.setDeliveryWindow("createSku");
          }}
          title={translate("equip.addSkus")}
          aria-label={translate("equip.addSkus")}
          sx={{ ml: 2, mb: 2 }}
        >
          {translate("equip.addSkus")}
        </Button>
      )}
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "inherit",
        }}
      >
        {permissions.vote({
          resource: "arrivals",
          attribute: "edit",
          subject: props.arrivalData,
        }) && (
          <Box>
            <Button
              size="small"
              variant="contained"
              onClick={() => props.setDeliveryWindow("edit")}
              title={translate("equip.ra.action.edit")}
              aria-label={translate("equip.ra.action.edit")}
            >
              {translate("ra.action.edit")}
            </Button>
          </Box>
        )}
        <Box>
          <Button
            onClick={() => {
              props.setDeliveryWindow();
              props.setArrivalData();
            }}
            title={translate("equip.close")}
            aria-label={translate("equip.close")}
          >
            {translate("equip.close")}
          </Button>
        </Box>
      </Toolbar>
    </Show>
  );
};
