import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import {
    required,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    Create,
    useTranslate,
    usePermissions,
    useResourceContext,
    useNotify,
} from "react-admin";
import { Button, Grid2 } from "@mui/material";
import { CategoryTree } from "./components/CategoryTree";
import {
    queryCreateToCategory,
    queryToUrlOrigin,
    queryToExpanded,
    queryToAttribute,
} from "shared/src/utils/decodeQuery";
import SaveIcon from "@mui/icons-material/Save";
import { AccessRightsError } from "../components/AccessRightsError";
import { handleHttpError } from "shared/src/utils/errorHandler";

export const CategoryCreate = (props) => {
    const [filter, setFilter] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [disableSave, setDisableSave] = useState(true);
    const resource = useResourceContext();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();
    const notify = useNotify();
    const navigate = useNavigate();
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const handleFilterChange = (filterInput) => {
        navigate({
            pathname: `/categories/create/${filterInput?.category ? filterInput.category : "0"}`,
            search: `?from=${queryToUrlOrigin()}`,
        });

        setFilter(filterInput.category ? filterInput : { category: "0" });
        if (filterInput.category) {
            getCategoryName(filterInput.category);
        } else {
            setCategoryName();
        }
    };

    const save = () => {
        const categoryName = document.getElementById("createCategoryInput").value;
        if (categoryName) {
            if (+filter.category > 0) {
                dataProvider
                    .addChildNode("categories", {
                        parentId: filter.category,
                        data: { name: categoryName },
                    })
                    .then((response) => {
                        navigateBack(response.data.id, filter.category);
                    })
                    .catch((e) => {
                        handleHttpError(e, notify);
                    });
            } else if (+filter.category === 0) {
                dataProvider
                    .addRootNode("categories", { data: { name: categoryName } })
                    .then((response) => {
                        navigateBack(response.data.id, filter.category);
                    })
                    .catch((e) => {
                        handleHttpError(e, notify);
                    });
            }
        }
    };

    const navigateBack = (id, parentId) => {
        const originURL = queryToUrlOrigin();
        const expanded = queryToExpanded(originURL);
        if (!expanded.includes(parentId)) {
            expanded.push(parentId);
        }

        const filterToStr = JSON.stringify({ category: `${id}` });
        const expandedToStr = JSON.stringify(expanded ? expanded : []);

        const encodedFilter = encodeURI(`filter=${filterToStr}`);
        const encodedExpanded = encodeURI(`expanded=${expandedToStr}`);

        navigate({
            pathname: "/categories",
            search: `?${encodedFilter}&order=ASC&${encodedExpanded}`,
        });
    };

    const handleTextInput = (e) => {
        if (disableSave && e.target.value) {
            setDisableSave(false);
        } else if (!disableSave && !e.target.value) {
            setDisableSave(true);
        }
    };

    const getCategoryName = useCallback(
        (id) => {
            return dataProvider
                .getOne("categories", { id: id })
                .then((response) => {
                    setCategoryName(response.data.name);
                })
                .catch((e) => {
                    handleHttpError(e, notify);
                });
        },
        [dataProvider, notify]
    );

    useEffect(() => {
        const queryFilter = queryCreateToCategory(window.location.hash);
        if (queryFilter?.category) {
            getCategoryName(queryFilter.category);
        }
        setFilter(queryFilter);
    }, [getCategoryName]);

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <Grid2 container spacing={2} mt={4}>
            <Grid2 size={{ xxl:2, lg:3, md:4, sm:5, xs:6 }}>
                <CategoryTree
                    handleFilterChange={handleFilterChange}
                    filter={filter}
                    id={queryCreateToCategory(window.location.hash)?.category}
                />
            </Grid2>
            <Grid2 size={{ xxl:10, lg:9, md:8, sm:7, xs:6 }}>
                <Create {...props}>
                    <SimpleForm toolbar={<CreateToolbar save={save} disablesave={disableSave} />}>
                        <p>
                            {filter?.category !== "0"
                                ? translate("equip.createCategoryUnder", {
                                      name: categoryName,
                                  })
                                : translate("equip.addMainCategory")}
                        </p>
                        <TextInput
                            source="name"
                            label="equip.name"
                            title={translate("equip.name")}
                            validate={[required()]}
                            sx={{ marginTop: 3 }}
                            id="createCategoryInput"
                            onChange={handleTextInput}
                        />
                    </SimpleForm>
                </Create>
            </Grid2>
        </Grid2>
    );
};

const CreateToolbar = (props) => {
    const translate = useTranslate();
    return (
        <Toolbar
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
            {...props}
        >
            <Button
                startIcon={<SaveIcon />}
                variant="contained"
                disabled={props.disablesave}
                onClick={props.save}
                size="medium"
                title={translate("equip.save")}
                aria-label={translate("equip.save")}
            >
                {translate("ra.action.save")}
            </Button>
            <Button
                variant="text"
                href={queryToUrlOrigin()}
                title={translate("equip.cancel")}
                aria-label={translate("equip.cancel")}
            >
                {translate("ra.action.cancel")}
            </Button>
        </Toolbar>
    );
};
