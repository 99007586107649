import {
    TextField,
    useRecordContext,
    Datagrid,
    ReferenceManyField,
    ReferenceField,
    NumberField,
    useTranslate,
    usePermissions,
} from "react-admin";
import { Box, Button } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { RaDateField } from "../../../components/fields/Ra/RaDateField";
import { RaDeleteButton } from "../../../components/buttons/RaDeleteButton";

export const ArrivalsList = (props) => {
    const { filter, ...rest } = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const resource = "arrivals";
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }

    return (
        <Box>
            {permissions.vote({
                resource,
                attribute: "create",
                subject: record,
            }) && (
                <Button
                    onClick={() => props.setDeliveryWindow("edit")}
                    size="small"
                    variant="text"
                    startIcon={<LocalShippingIcon />}
                    title={translate("equip.newDelivery")}
                    aria-label={translate("equip.newDelivery")}
                    sx={{ marginY: 2, marginLeft: 1, display: "flex" }}
                >
                    {translate("equip.newDelivery")}
                </Button>
            )}
            <ReferenceManyField
                {...rest}
                filter={{
                    // Use a very special key to ensure it does not conflict with any other filter
                    "@@record": record,
                    ...filter,
                }}
                reference="arrivals"
                target="arrivals"
            >
                <Datagrid
                    rowClick={(id, resource, data) => {
                        props.setDeliveryWindow("show");
                        props.setArrivalData(data);
                    }}
                    bulkActionButtons={false}
                >
                    <TextField label="equip.orderNumber" source="purchaseOrderNumber" />
                    <ReferenceField source="supplierId" reference="suppliers" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField label="equip.quantity" source="quantity" />
                    <RaDateField label="equip.orderDate" source="orderDate" />
                    <RaDateField label="equip.deliveryDate" source="deliveryDate" />
                    <TextField label="equip.comment" source="comment" />
                    {record.isMassProduct && (
                        <NumberField
                            label="equip.purchasePricePerItem"
                            source="purchasePrice"
                            options={{ style: "currency", currency: "EUR" }}
                        />
                    )}
                    {record.isMassProduct && (
                        <NumberField
                            label="equip.currentPrice"
                            source="currentPrice"
                            options={{ style: "currency", currency: "EUR" }}
                            sortable={false}
                        />
                    )}
                    <RaDeleteButton confirmDelete={true} redirect={false} />
                </Datagrid>
            </ReferenceManyField>
        </Box>
    );
};
