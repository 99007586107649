import {
    required,
    Toolbar,
    useRecordContext,
    TextField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    ReferenceManyField,
    NumberField,
    Datagrid,
    useTranslate,
    usePermissions,
} from "react-admin";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { RaDateField } from "../../../../components/fields/Ra/RaDateField";

export const EditSkuForm = (props) => {
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <></>;
    }
    return (
        <Box px={2}>
            <Show
                resource="arrivals"
                id={props.arrivalData.id}
                sx={{
                    "& .RaShow-card": { background: "inherit", boxShadow: "none" },
                }}
            >
                <Box pl={2} mb={2}>
                    <Typography variant="p">{translate("equip.editSkus")}</Typography>
                </Box>
                <SimpleShowLayout>
                    <TextField
                        label="equip.purchaseOrderNumber"
                        source="purchaseOrderNumber"
                        validate={[required()]}
                    />

                    <RaDateField
                        label="equip.orderDate"
                        source="orderDate"
                        validate={[required()]}
                    />
                    <ReferenceField
                        label="equip.supplier"
                        source="supplierId"
                        reference="suppliers"
                        link={false}
                    >
                        <TextField label="equip.name" source="name" />
                    </ReferenceField>

                    <TextField label="equip.comment" source="comment" validate={[required()]} />
                </SimpleShowLayout>
                <Box>
                    <ReferenceManyField
                        reference="skus"
                        addLabel={false}
                        perPage={100}
                        target="arrival"
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField label="equip.labelNumber" source="labelNumber" />
                            <TextField label="equip.manufacturer" source="manufacturer.name" />
                            <TextField label="equip.supplier" source="supplier.name" />
                            <NumberField
                                label="equip.currentPrice"
                                source="currentPrice"
                                options={{
                                    style: "currency",
                                    currency: "EUR",
                                }}
                                sortable={false}
                            />
                            <NumberField
                                label="equip.weight"
                                source="weight"
                                options={{
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                    style: "unit",
                                    unit: "kilogram",
                                }}
                            />
                            <TextField label="equip.serialNumber" source="serialNumber" />
                            <EditButton title={translate("equip.edit")} {...props} />
                        </Datagrid>
                    </ReferenceManyField>
                </Box>
                {permissions.vote({
                    resource: "skus",
                    attribute: "create",
                    subject: props.skuRecord,
                }) && (
                    <Button
                        sx={{ ml: 1.7 }}
                        size="small"
                        onClick={() => {
                            props.setDeliveryWindow("createSku");
                        }}
                        title={translate("equip.addSkus")}
                        aria-label={translate("equip.addSkus")}
                    >
                        {translate("equip.addSkus")}
                    </Button>
                )}

                <Toolbar
                    sx={{
                        background: "inherit",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px 16px!important",
                    }}
                >
                    {permissions.vote({
                        resource: "arrivals",
                        attribute: "edit",
                        subject: props.arrivalData,
                    }) && (
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => props.setDeliveryWindow("edit")}
                            title={translate("ra.action.edit")}
                            aria-label={translate("ra.action.edit")}
                        >
                            {translate("ra.action.edit")}
                        </Button>
                    )}

                    <Button
                        onClick={() => {
                            props.setDeliveryWindow();
                            props.setArrivalData();
                        }}
                        title={translate("equip.close")}
                        aria-label={translate("equip.close")}
                    >
                        {translate("equip.close")}
                    </Button>
                </Toolbar>
            </Show>{" "}
        </Box>
    );
};

const EditButton = (props) => {
    const record = useRecordContext();
    const translate = useTranslate();
    const edit = () => {
        props.setDeliveryWindow("createSku");
        props.setSkuRecord(record);
    };
    return (
        <Button
            startIcon={<EditIcon />}
            size="small"
            variant="text"
            onClick={edit}
            title={translate("ra.action.edit")}
            aria-label={translate("ra.action.edit")}
        >
            {translate("ra.action.edit")}
        </Button>
    );
};
