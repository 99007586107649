import { useState } from "react";
import {
    useNotify,
    useRecordContext,
    useTranslate,
    useLocaleState,
    ImageField,
    useResourceContext,
} from "react-admin";
import { MenuItem, Menu, Button, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleHttpError } from "shared/src/utils/errorHandler";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile } from "shared/src/utils/downloadHandler";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { useLocation } from "react-router";

export const ProductImageManagement = () => {
    const resource = useResourceContext();
    const view = queryToAttribute();
    const notify = useNotify();
    const record = useRecordContext();
    const [locale] = useLocaleState();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const translate = useTranslate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const download = (e) => {
        const imageType = e.target.dataset.menuitem;
        handleClose();
        const uri = `/products/${record.id}/${imageType}`;
        downloadFile(uri, null, record.name[locale], notify);
    };

    return (
        <>
            <ImageField source={`_links.${resource === "skus" ? "product_" : ""}thumbnail`} />
            {!location.pathname.includes("create") &&
                resource === "products" &&
                !location.pathname.includes("show") && (
                    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Box>
                            <Button
                                id="download-image-button"
                                aria-controls={open ? "download-image-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                sx={view === "read" ? { marginLeft: 1 } : {}}
                                startIcon={<DownloadIcon />}
                                onClick={handleClick}
                                title={translate("equip.download")}
                                aria-label={translate("equip.download")}
                            >
                                {translate("equip.download")}
                            </Button>
                            <Menu
                                id="download-image-menu"
                                MenuListProps={{
                                    "aria-labelledby": "download-image-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem
                                    title={"Download a thumbnail"}
                                    aria-label={"Download a thumbnail"}
                                    data-menuitem={"thumbnail"}
                                    onClick={download}
                                >
                                    {"Download a thumbnail"}
                                </MenuItem>
                                <MenuItem
                                    title={"Download a full image"}
                                    aria-label="Download a full image"
                                    data-menuitem={"image"}
                                    onClick={download}
                                >
                                    {"Download a full image"}
                                </MenuItem>
                            </Menu>
                        </Box>
                        {view === "write" && (
                            <Button
                                startIcon={<DeleteIcon />}
                                color="error"
                                onClick={() => {
                                    fetch(`/products/${record.id}/image`, {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    })
                                        .then(() => {
                                            window.location.reload();
                                        })
                                        .catch((e) => {
                                            handleHttpError(e, notify);
                                        });
                                }}
                                title={translate("equip.deleteImage")}
                                aria-label={translate("equip.deleteImage")}
                            >
                                {translate("equip.deleteImage")}
                            </Button>
                        )}
                    </Box>
                )}
        </>
    );
};
