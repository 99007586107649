import {
    AutocompleteArrayInput,
    Filter,
    ReferenceArrayInput,
    ReferenceInput,
    TextInput,
} from "react-admin";
import { RaSelectInput } from "../../components/inputs/Ra/RaSelectInput";
import { RaAutocompleteInput } from "../../components/inputs/Ra/RaAutocompleteInput";
import { RaTextInput } from "../../components/inputs/Ra/RaTextInput";
import { Box } from "@mui/material";

export const CategoryListFilter = (props) => {
    return (
        <Box
            sx={{
                "& div": {
                    "& input": {
                        minWidth: 90,
                        maxWidth: 160,
                    },
                },
                px: 2,
            }}
        >
            <Filter {...props}>
                <RaTextInput source="name" variant="outlined" alwaysOn />
                <TextInput source="description" alwaysOn variant="outlined" />
                <TextInput source="labelNumber" alwaysOn variant="outlined" />
                <TextInput
                    label="equip.edispo.id"
                    source="edispoId"
                    alwaysOn
                    variant="outlined"
                />
                <RaSelectInput
                    source="isMassProduct"
                    label="equip.type"
                    variant="outlined"
                    choices={[
                        {
                            id: "0",
                            name: "equip.individualSkus",
                        },
                        { id: "1", name: "equip.massProduct" },
                    ]}
                    alwaysOn
                />
                <ReferenceArrayInput source="tags" reference="tags" alwaysOn>
                    <AutocompleteArrayInput
                        variant="outlined"
                        filterToQuery={(input) => {
                            return { name: input };
                        }}
                    />
                </ReferenceArrayInput>
                <ReferenceInput
                    alwaysOn
                    source="supplier"
                    reference="suppliers"
                    sort={{ field: "name", order: "ASC" }}
                >
                    <RaAutocompleteInput
                        variant="outlined"
                        label="equip.supplier"
                        field="name"
                    />
                </ReferenceInput>
                <ReferenceInput
                    alwaysOn
                    source="manufacturer"
                    reference="manufacturers"
                    sort={{ field: "name", order: "ASC" }}
                >
                    <RaAutocompleteInput
                        variant="outlined"
                        label="equip.manufacturer"
                        field="name"
                    />
                </ReferenceInput>
            </Filter>
        </Box>
    );
};
