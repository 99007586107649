import { useState } from "react";
import {
    FunctionField,
    NumberField,
    SimpleShowLayout,
    TextField,
    usePermissions,
    useResourceContext,
    useTranslate,
    TabbedShowLayout,
    Tab,
    useLocaleState,
} from "react-admin";
import { Box, Grid2 } from "@mui/material";
import { queryToAttribute } from "shared/src/utils/decodeQuery";
import { ToolbarWithHistory } from "../components/ToolbarWithHistory";
import { AccessRightsError } from "../components/AccessRightsError";
import { SkuChangeLogs } from "./components/SkuChangeLogs";
import { PrintLabels } from "../components/PrintLabels";
import { EliminateForm } from "../components/EliminateForm";
import { Notes } from "./components/notes/Notes";
import { ProductImageManagement } from "../products/components/productImage/ProductImageManagement";
import { Rentals } from "./components/Rentals";
import { RaDateField } from "../components/fields/Ra/RaDateField";
import { EliminateButton } from "../components/buttons/EliminateButton";
import { RaShowView } from "../components/views/RaShowView";

export const SkuShow = (props) => {
    const [eliminate, setEliminate] = useState(false);
    const [printLabels, setPrintLabels] = useState(false);
    const resource = useResourceContext();
    const translate = useTranslate();
    const attribute = queryToAttribute();
    const { isLoading, permissions } = usePermissions();
    const [locale] = useLocaleState();

    if (isLoading) {
        return <></>;
    } else if (!permissions.vote({ resource, attribute })) {
        return <AccessRightsError />;
    }
    return (
        <RaShowView
            marginBottom={3}
            {...props}
            actions={
                <ToolbarWithHistory
                    back={true}
                    edit={true}
                    print={true}
                    setPrintLabels={setPrintLabels}
                    download={true}
                    downloadLabel={translate("equip.downloadEliminationPdf")}
                    downloadLink="elimination_pdf"
                />
            }
        >
            {eliminate && <EliminateForm setEliminate={setEliminate} />}
            {printLabels && <PrintLabels setPrintLabels={setPrintLabels} />}
            <Box flex={1} pt="1em">
                <Grid2 position={"relative"} container>
                    <Grid2 m={6} justifyContent={"flex-start"} marginLeft={0}>
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <ProductImageManagement />
                            <SimpleShowLayout>
                                <TextField
                                    label="equip.name"
                                    source={`product.name.${locale}`}
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <TextField
                                    label="equip.description"
                                    source={`product.description.${locale}`}
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <TextField
                                    label="equip.manufacturer"
                                    source="manufacturer.name"
                                    emptyText={translate("equip.notAvailable")}
                                />
                            </SimpleShowLayout>
                        </Box>
                        <Box marginTop={3} marginLeft={7}>
                            <SimpleShowLayout>
                                <TextField
                                    label="equip.labelNumber"
                                    source="labelNumber"
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <TextField
                                    label="equip.serialNumber"
                                    source="serialNumber"
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <TextField
                                    emptyText={translate("equip.notAvailable")}
                                    label="equip.edispo.id"
                                    source="product.edispoId"
                                />
                                <FunctionField
                                    label="equip.edispo.type"
                                    render={(record) =>
                                        translate(
                                            record.product.edispoType
                                                ? `equip.edispo.${record.product.edispoType}`
                                                : "equip.notAvailable"
                                        )
                                    }
                                />
                                <FunctionField
                                    label="equip.status"
                                    render={(record) =>
                                        translate(`equip.sku_status_${record.status}`)
                                    }
                                />
                                <FunctionField
                                    label="equip.availability"
                                    render={(record) =>
                                        translate(`equip.sku_availability_${record.availability}`)
                                    }
                                />
                                <EliminateButton
                                    setEliminate={setEliminate}
                                />
                            </SimpleShowLayout>
                        </Box>
                    </Grid2>
                    <Grid2 m={6}>
                        <Box>
                            <SimpleShowLayout>
                                <TextField
                                    label="equip.supplier"
                                    source="supplier.name"
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <TextField
                                    label="equip.storageLocation"
                                    source="storageLocation.name"
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <RaDateField
                                    label="equip.deliveryDate"
                                    source="arrival.deliveryDate"
                                    emptyText={translate("equip.notAvailable")}
                                />
                            </SimpleShowLayout>
                        </Box>
                        <Box marginTop={2.8}>
                            <SimpleShowLayout>
                                <NumberField
                                    label="equip.purchasePrice"
                                    source="arrival.purchasePrice"
                                    options={{
                                        style: "currency",
                                        currency: "EUR",
                                    }}
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <NumberField
                                    label="equip.currentPrice"
                                    source="currentPrice"
                                    options={{
                                        style: "currency",
                                        currency: "EUR",
                                    }}
                                    emptyText={translate("equip.notAvailable")}
                                />
                                <NumberField
                                    label="equip.weight"
                                    source="weight"
                                    options={{
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                        style: "unit",
                                        unit: "kilogram",
                                    }}
                                    emptyText={translate("equip.notAvailable")}
                                />
                            </SimpleShowLayout>
                        </Box>
                    </Grid2>
                </Grid2>
                <Grid2 container>
                    <Grid2 size={{ xs: 12 }}>
                        <TabbedShowLayout>
                            {permissions.vote({
                                resource: "changeLogs",
                                attribute: "read",
                            }) && (
                                <Tab label="equip.changeLogs" title={translate("equip.changeLogs")}>
                                    {<SkuChangeLogs />}
                                </Tab>
                            )}

                            <Tab label="equip.rentals" title={translate("equip.rentals")}>
                                <Rentals />
                            </Tab>

                            <Tab label="equip.comments" title={translate("equip.comments")}>
                                <Notes />
                            </Tab>
                        </TabbedShowLayout>
                    </Grid2>
                </Grid2>
            </Box>
        </RaShowView>
    );
};
