import { useState, useRef, useEffect, useCallback } from "react";
import { useTranslate, useDataProvider, useNotify } from "react-admin";
import { CircularProgress, Typography, Autocomplete, Box } from "@mui/material";
import { debounce } from "lodash";

export const AutocompleteRenterInput = ({
    renter,
    setRenter,
    RenderInputComponent,
    handleError,
    alwaysOn = true,
    helperText = "",
    ...props
}) => {
    const PER_PAGE = 10;
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [isRenterListExhausted, setIsRenterListExhausted] = useState(false);
    const [isRenterListLoading, setIsRenterListLoading] = useState(true);
    const [renters, setRenters] = useState([]);
    const renterInputRef = useRef(null);
    const initialized = useRef(false);
    const getRentersRef = useRef((search, renters) => {
        const page = Math.floor(renters.length / PER_PAGE) + 1;
        dataProvider
            .getList("renters", {
                filter: {
                    search,
                },
                pagination: { page, perPage: PER_PAGE },
                sort: { field: "lastname", order: "ASC" },
            })
            .then((response) => {
                setIsRenterListLoading(false);
                setRenters([...renters, ...response.data]);
                if (response.data.length < PER_PAGE) {
                    setIsRenterListExhausted(true);
                }
            })
            .catch((e) => handleError(e, notify));
    });

    const getRenters = useCallback(
        debounce((...args) => getRentersRef.current(...args), 300),
        []
    );

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        const userIdentifier = renterInputRef.current.value;
        initialized.current = true;
        getRenters(userIdentifier, renters);
    }, [getRenters, renters]);

    return (
        <Autocomplete
            title={translate("equip.renter")}
            label={translate("equip.renter")}
            value={renter}
            onInputChange={(event, value) => {
                setIsRenterListExhausted(false);
                getRenters(value, []);
            }}
            onChange={(event, renterOption) => {
                setRenter(renterOption);
            }}
            ListboxProps={{
                onScroll: (event) => {
                    const listboxNode = event.currentTarget;
                    if (
                        listboxNode.scrollTop + listboxNode.clientHeight ===
                            listboxNode.scrollHeight &&
                        !isRenterListExhausted
                    ) {
                        const userIdentifier = renterInputRef.current.value;
                        getRenters(userIdentifier, renters);
                    }
                },
            }}
            getOptionDisabled={(option) => !option.id}
            options={[...renters, ...(isRenterListLoading ? [{}] : [])]}
            renderInput={(params) => (
                <RenderInputComponent
                    {...params}
                    label={translate("equip.renter")}
                    inputRef={renterInputRef}
                />
            )}
            getOptionLabel={(params) => {
                if (typeof params === "string") return params;
                return params.fullName;
            }}
            renderOption={(props, option) => {
                const { fullName, userIdentifier, id } = option;
                const { key, ...rest } = props;
                return id ? (
                    <Box
                        title={fullName}
                        label={fullName}
                        key={id}
                        {...rest}
                    >
                        <Box p={1} m={1} key={id}>
                            <Typography>{fullName} ({userIdentifier})</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        mt={2}
                        mb={2}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress size={16} />
                        <Typography ml={1}>
                            {translate("equip.loading")}...
                        </Typography>
                    </Box>
                );
            }}
            {...props}
        />
    );
};
