import {
    LoadingIndicator,
    Logout,
    AppBar as ReactAppBar,
    ToggleThemeButton,
    useAuthProvider,
    useLocaleState,
    usePermissions,
    UserMenuContext,
    useTheme,
} from "react-admin";
import Typography from "@mui/material/Typography";
import logo from "shared/src/imgs/filmakademie2016.png";
import darkLogo from "shared/src/imgs/filmakademie2016-dark.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, Menu, Tooltip } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

const AppBar = (props) => {
    const [locale, setLocale] = useLocaleState();
    const { permissions } = usePermissions();
    const [theme] = useTheme();

    useEffect(() => {
        const userLocale = permissions?.user?.locale;
        const userLanguage = userLocale?.split("_")[0];

        if (userLanguage && locale !== userLanguage) {
            setLocale(userLanguage);
        }
    }, [locale, setLocale, permissions]);

    return (
        <ReactAppBar
            {...props}
            userMenu={false}
            toolbar={
                <>
                    <ToggleThemeButton />
                    <LoadingIndicator />
                    <TitledUserMenu />
                </>
            }
        >
            <Box
                component="img"
                src={theme === "dark" ? darkLogo : logo}
                alt="Logo"
                height={"20px"}
                width={"auto"}
                marginRight="2rem"
                marginLeft="1rem"
            ></Box>
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                sx={{
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            />
        </ReactAppBar>
    );
};

const TitledUserMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const authProvider = useAuthProvider();
    const { permissions } = usePermissions();
    const user = permissions?.user;
    const tooltip =
        user &&
        `${user.firstname} ${user.lastname} \n ${user.username} \n ${user.roles
            .map((role) => role)
            .join("\n")}`;

    const { children = !!authProvider ? <Logout /> : null, icon = <AccountCircle /> } = props;

    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = useCallback(() => setAnchorEl(null), []);
    const context = useMemo(() => ({ onClose: handleClose }), [handleClose]);
    if (!children) return null;
    const open = Boolean(anchorEl);

    return (
        <div>
            <Tooltip title={<div style={{ whiteSpace: "pre-line", lineHeight: "1.8" }}>{tooltip}</div>}>
                <IconButton
                    aria-label={"hey"}
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup={true}
                    color="inherit"
                    onClick={handleMenu}
                >
                    {icon}
                </IconButton>
            </Tooltip>
            <UserMenuContextProvider value={context}>
                <Menu
                    id="menu-appbar"
                    disableScrollLock
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    {children}
                </Menu>
            </UserMenuContextProvider>
        </div>
    );
};

export const UserMenuContextProvider = ({ children, value }) => (
    <UserMenuContext.Provider value={value}>{children}</UserMenuContext.Provider>
);

export default AppBar;
